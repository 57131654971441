var Tivoli = Tivoli || {};

Tivoli.Lightbox = (function () {
    var fields = {
        selector: ".js-lightbox",
        selectorEvent: "click",
        container: "#site-container",

        // For later use:
        lightboxGroupAttribute: "group",

        headerSelector: "#head-section",
        menuSelector: "#nav-section > .scroll-wrapper",

        // Properties for calculation purposes
        maxContentSize: 0.7, // percent viewport width / height

        windowHeight: 0,
        windowWidth: 0,
        headerHeight: 0,
        iconCloseHeight: 35,
        menuWidth: 0,
        isMobile: false,

        currentWidth: 0,
        currentHeight: 0,
        currentScrollTop: 0
    };

    var elementArray = [];
    var elementArrayLength;
    var activeLightBoxElement;

    // Elements
    var lightBoxCreated = false,
        isActive = false,
        scrollContainer = $("body"),
        lightbox,
        lightboxContent,
        header,
        menu;

    var startBounds;

    var init = function (options) {
        var lightboxes = $(fields.selector);
        if (lightboxes.length <= 0) {
            return;
        }

        $.extend(fields, options);
        header = $(fields.headerSelector);
        menu = $(fields.menuSelector);

        setWindowProperties();

        $('.swiper .js-lightbox').each(function () {
            var elementToPush = new Array();
            elementToPush[0] = $(this).attr("href");
            elementToPush[1] =  $(this).hasClass("js-lightbox-iframe");
            elementToPush[2] = $(this).data("title");
            elementToPush[3] = $(this);
            elementArray.push(elementToPush);
        });
        elementArrayLength = elementArray.length;

        if (!lightBoxCreated) {
            insertLightBox();
            $(window).smartresize(onResize);

            $(document).on('menuClose', onResize);
            $(document).on('menuOpen', onResize);
            $('.js-lightbox-next').on('click', function (e) {
                e.preventDefault();
                updateNextButton();
            });
            $('.js-lightbox-prev').on('click', function (e) {
                e.preventDefault();
                updatePrevButton();
            });

            if (elementArrayLength <= 1) {
                $('.js-lightbox-next').hide();
                $('.js-lightbox-prev').hide();
            }

            $("body").keydown(function (e) {
                if (e.keyCode == 37) { // left
                    updatePrevButton();
                }
                if (e.keyCode == 39) { // right
                    updateNextButton();
                }
                if (e.keyCode == 27) { //escape
                    hideLightBox();
                }
            });
        }
        lightboxes.on(fields.selectorEvent, activateLightBox);
    };

    var onResize = function () {
        setWindowProperties();

        if (isActive) {
            setContentBounds();
        }
    };

    var setWindowProperties = function () {
        var $window = $(window);

        fields.windowHeight = $window.height();
        fields.windowWidth = $window.width();
        fields.headerHeight = header[0].offsetHeight;
        fields.isMobile = Modernizr.touch && $window.width() < Tivoli.settings.width.medium;
        fields.currentScrollTop = scrollContainer.scrollTop();
    };

    var getFixedDimensions = function (item) {
        return {
            width: item[0].clientWidth,
            height: item[0].clientHeight,
            left: item.offset().left,
            top: item.offset().top - fields.currentScrollTop
        };
    };

    var insertLightBox = function () {
        lightbox = $("<div class='lightbox lightbox--hidden'><div class='lightbox__layer'></div><div class='lightbox__content'><a class='js-lightbox-next lightbox__next color-gold' data-link=''><i class='icon-arrow-right'></i></a><a class='js-lightbox-prev lightbox__prev color-gold' data-link=''><i class='icon-arrow-left'></i></a><div class='lightbox__close button-plus color-gold transform'></div></div></div>");
        lightboxContent = lightbox.find(".lightbox__content");

        var close = lightbox.find(".lightbox__close, .lightbox__layer");
        close.on(fields.selectorEvent, hideLightBox);

        $(fields.container).after(lightbox);

        lightBoxCreated = true;
    };

    var updateNextButton = function () {
        if (activeLightBoxElement + 1 == elementArrayLength) {
            activeLightBoxElement = 0;
        } else {
            activeLightBoxElement = activeLightBoxElement + 1;
        }

        if (elementArray[activeLightBoxElement][1]) {
            checkVideoCookieAndInsert(elementArray[activeLightBoxElement][3], true, true);
        } else {
            insertImage(elementArray[activeLightBoxElement][0], true, elementArray[activeLightBoxElement][2]);
        }
    };

    var updatePrevButton = function () {
        if (activeLightBoxElement == 0) {
            activeLightBoxElement = elementArrayLength - 1;
        } else {
            activeLightBoxElement = activeLightBoxElement - 1;
        }

        if (elementArray[activeLightBoxElement][1]) {
            checkVideoCookieAndInsert(elementArray[activeLightBoxElement][3], true, true);
        } else {
            insertImage(elementArray[activeLightBoxElement][0], true, elementArray[activeLightBoxElement][2]);
        }
    };

    var getElementNumber = function (src) {
        var elementNumber;
        $.each(elementArray, function (key, info) {
            if (info[0] == src) {
                elementNumber = key;
            }
        });

        return elementNumber;
    };

    var checkYoutubeCookieConsent = function() {
        var services = UC_UI ? UC_UI.getServicesBaseInfo() : [];
        var service = services.find(({ name }) => name === "YouTube Video");
        var consent = service ? service.consent.status : false;

        return consent;
    };


    var checkVideoCookieAndInsert = function (lightboxItem, shouldUpdateIframe, shouldUpdateImage) {
        var consent = checkYoutubeCookieConsent();
        if (consent) {
            insertIFrame(lightboxItem.attr("href"), shouldUpdateIframe);
        } else {
            var headline;
            var description;
            var button;
            var cookieComplienceObj = $("#VideoCookieConsent");
            var imgContent = "";
            var firstImgChildSrc = "";

            if (cookieComplienceObj) {
                var cookieComplienceHtml = cookieComplienceObj.html().trim();
                var data = JSON.parse(cookieComplienceHtml);

                if (data) {
                    headline = data.Headline ? "<span class='lightbox__content__item__headline'>" + data.Headline + "</span>" : null;
                    description = data.Description ? "<span class='lightbox__content__item__description'>" + data.Description + "</span>" : null;
                    button = data.ButtonText ? "<button class='lightbox__content__item__button'>" + data.ButtonText + "</button>" : null;
                    firstImgChildSrc = data.DefaultImageUrl;
                }
            }

            if (headline) {
                imgContent += headline;
            }
            if (description) {
                imgContent += description;
            }
            if (button) {
                $(button).prop("title", data.ButtonText);
                imgContent += button;
            }

            var imgText = "<span class='lightbox__content__item__layover'></span><span class='lightbox__content__item__wrapper'><span class='lightbox__content__item__inner'>" + imgContent + "</span></span>";
            var firstImgChild = lightboxItem.find('img').first();

            if (firstImgChild && firstImgChild.attr("src")) {
                firstImgChildSrc = firstImgChild.attr("src");
            }

            insertImage(firstImgChildSrc, shouldUpdateImage, imgText, "lightbox__content__wrapper");
            $('.lightbox__content__item__button').on('click', function (e) {
                if (UC_UI) {
                    hideLightBox();
                    UC_UI.showFirstLayer()
                }
            });
        }
    }

    var activateLightBox = function (e) {
        var item = $(this),
            isIframe = item.hasClass("js-lightbox-iframe");

        activeLightBoxElement = getElementNumber(item.attr("href"));

        e.preventDefault();

        fields.currentScrollTop = scrollContainer.scrollTop();
        startBounds = getFixedDimensions(item);

        if (isIframe) {
            checkVideoCookieAndInsert(item, false, false);

        } else {
            insertImage(item.attr("href"), false, item.data('title')); 
        }
    };

    var insertImage = function (src, update, title, contentClass) {
        var timeout,
            isLoaded;

        function showImage() {
            if (isLoaded) {
                clearTimeout(timeout);
                setContentBounds();
                return;
            }

            timeout = setTimeout(function () {
                showImage();
            }, 200);
        };

        lightboxContent.attr("style", "");
        lightboxContent.removeClass("lightbox__content__wrapper");

        if (contentClass) {
            lightboxContent.addClass(contentClass);
        }

        if (update) {
            var newImg = $("<img />").attr("src", src)
                .load(function () {
                    isActive = true;
                    isLoaded = true;
                    fields.currentWidth = this.width;
                    fields.currentHeight = this.height;
                });
        }
        var img = $("<img class='lightbox__content__item'/>")
            .attr("src", src)
            .load(function () {
                isActive = true;
                isLoaded = true;

                if (!update) {
                    fields.currentWidth = this.width;
                    fields.currentHeight = this.height;
                }

                if (!isSmallDevice() && !update) {
                    lightboxContent.css(startBounds);
                } else {
                    setContentBounds();
                }
                if (!update) {
                    lightbox.toggleClass("lightbox--hidden");
                }
            });

        var imgText = "<span class='lightbox__content__item__text'>" + title + "</span>";

        if (update) {
            var lightBoxItem = $('.lightbox__content__item');
            var lightBoxText = $('.lightbox__content__item__text');
            lightBoxItem.hide();
            lightboxContent.append(img);
            lightboxContent.append(imgText);
            setContentBounds();
            showImage();
            lightBoxItem.remove();
            lightBoxText.remove();
        } else {
            lightboxContent.append(img);
            lightboxContent.append(imgText);
            if (!isSmallDevice()) {
                showImage();
            }
        }

    };

    var insertIFrame = function (src, update) {
        // Youtube use 16:9 players
        var iframe = $('<iframe class="lightbox__iframe" frameborder="0" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen></iframe>');
        var iframeContainer = $('<div class="lightbox__radius lightbox__content__item"></div>');
        iframe.attr("src", src);

        isActive = true;
        fields.currentWidth = 1920;
        fields.currentHeight = 1080;

        lightboxContent.css(startBounds);
        if(!update) {
            lightbox.toggleClass("lightbox--hidden");
        }
        setContentBounds();

        if (update) {
            $('.lightbox__iframe').remove();
            $('.lightbox__content__item').remove();
            $('.lightbox__content__item__text').remove();
        }

        lightboxContent.append(iframeContainer);
        var iframeContent = lightbox.find(".lightbox__radius");
        iframeContent.append(iframe);
    };

    var setContentBounds = function () {
        var width = fields.currentWidth,
            height = fields.currentHeight;

        var //viewportWidth = fields.windowWidth - fields.menuWidth, // THIS is not needed anymore
            // viewportHeight = fields.windowHeight - fields.headerHeight;
            viewportWidth = fields.windowWidth,
            viewportHeight = fields.windowHeight - fields.headerHeight;

        var scale = 1;
        var maxContentSize = isSmallDevice() ? 1 : fields.maxContentSize;
        var heightRatio = height / viewportHeight;
        var widthRatio = width / viewportWidth;

        if (heightRatio > maxContentSize) {
            scale = maxContentSize / heightRatio;
        }
        if (widthRatio > maxContentSize > heightRatio) {
            scale = maxContentSize / widthRatio;
        }

        width = width * scale;
        height = height * scale;

        var x = (viewportWidth - width) / 2;
        var y = (viewportHeight - height) / 2 + fields.headerHeight - fields.iconCloseHeight;
        lightboxContent.css({ width: width, height: height, left: x, top: y });
    };

    var hideLightBox = function () {
        if (!isActive) {
            return;
        }

        isActive = false;
        if (!isSmallDevice()) {
            lightboxContent.css(startBounds);
            setTimeout(function () {
                lightbox.toggleClass("lightbox--hidden");
                lightboxContent.find(".lightbox__content__item").remove();
                lightboxContent.find(".lightbox__content__item__text").remove();
                lightboxContent.removeClass("lightbox__content__wrapper");
            }, 300);
        } else {
            lightboxContent.removeAttr("style");
            lightbox.toggleClass("lightbox--hidden");
            lightboxContent.find(".lightbox__content__item").remove();
            lightboxContent.find(".lightbox__content__item__text").remove();
            lightboxContent.removeClass("lightbox__content__wrapper");
        }
    };

    var isSmallDevice = function () {
        return Tivoli.vars.windowWidth < Tivoli.vars.mobileThreshold;
    };

    $(document).ready(function () {
        init();
    });

    $(document).on("jsonLoaded", function () {
        init();
    });

    return {
        hide: hideLightBox
    };
})();